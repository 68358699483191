export const WorkingHours = [{
    day: 'Luni',
    hours: '09:00-18:00'
},
{
    day: 'Marti',
    hours: '09:00-18:00'
},
{
    day: 'Miercuri',
    hours: '09:00-18:00'
},
{
    day: 'Joi',
    hours: '09:00-18:00'
},
{
    day: 'Vineri',
    hours: '09:00-18:00'
},
{
    day: 'Sambata',
    hours: '09:00-13:00'
},
{
    day: 'Duminica',
    hours: 'Inchis'
},]